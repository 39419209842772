import React, { Suspense, lazy, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Biconomy from "./hooks/biconomy";
import SizeDetector from "./hooks/size-detector";
import { biconomyContext } from "./utils/context";
import Loader from "./components/loader";
import Guard from "./utils/guard";
import ArtistChecker from "./hooks/is-artist";
import Modal from "./components/ContestModal";

const HomePage = lazy(() => import("./pages/home"));
const Explore = lazy(() => import("./pages/explore"));
const Profile = lazy(() => import("./pages/profile"));
const Mint = lazy(() => import("./pages/mint"));
const SingleArt = lazy(() => import("./pages/single-art"));
const ListArt = lazy(() => import("./pages/list-art"));
const MyWallet = lazy(() => import("./pages/wallet"));
const AboutUS = lazy(() => import("./pages/about"));
const SearchPage = lazy(() => import("./pages/search"));
const ExploreArtists = lazy(() => import("./pages/artists"));
const ARCapture = lazy(() => import("./pages/ar-capture"));
function App() {
  const enableScroll = () => {
    document.body.style.overflow = "auto";
  };
  const { particle, smartAccount, walletAddress, connect } = Biconomy();
  const pathname = useLocation()?.pathname;
  const { isMobile } = SizeDetector();
  useEffect(() => {
    window.scrollTo(0, 0);
    enableScroll();
  }, [pathname]);
  const { isArtist } = ArtistChecker();
  return (
    <biconomyContext.Provider
      value={{ particle, smartAccount, walletAddress, connect }}
    >
      <Guard />
      {/* <Modal onClose={() => {}} isOpen={true} /> */}

      <Suspense fallback={<Loader height={"100vh"} />}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/profile/:identifier" element={<Profile />} />
          <Route path="/profile/:identifier/:page" element={<Profile />} />
          <Route path="/explore/:page" element={<Explore />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/artists" element={<ExploreArtists />} />
          <Route path="/artists/:page" element={<ExploreArtists />} />
          <Route path="/mint" element={isArtist ? <Mint /> : <HomePage />} />
          <Route path="/art/:artId" element={<SingleArt />} />
          <Route path="/capture/:artId" element={<ARCapture />} />
          <Route path="/list/:artId" element={<ListArt />} />
          <Route path="/wallet" element={<MyWallet />} />
          <Route path="/about" element={<AboutUS />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/search/:page" element={<SearchPage />} />
        </Routes>
      </Suspense>
    </biconomyContext.Provider>
  );
}

export default App;
